const routes = [
  {
    index: true,
    label: 'Batchema Sombie',
    path: '/'
  },
  {
    label: 'About',
    path: '/about'
  },
  {
    label: 'Resume',
    path: '/resume'
  },
  {
    label: 'Projects',
    path: '/projects'
  },
  // {
  //   label: 'Stats',
  //   path: '/stats',
  // },
  {
    label: 'Contact',
    path: '/contact'
  }
];

export default routes;
