import React from "react";
import { Link } from "react-router-dom";

import ContactIcons from "../Contact/ContactIcons";

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </Link>
      <header>
        <h2>Batchema E. Sombie</h2>
        <p>
          <a href="mailto:batchema@seas.upenn.edu">Email me</a>
        </p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>
        Platform Software Engineer at {"  "}
        <a href="https://www.creditkarma.com" target="_blank" rel="noreferrer">
          Credit Karma Inc.
        </a>{" "}
        Passionate About Artificial Intelligence, software infrastructure and
        clever algorithms. Please, do not hesitate to{" "}
        <Link to="/contact">contact me</Link>
      </p>
      <ul className="actions">
        <li>
          {window.location.pathname.includes("/resume") ? (
            <Link to="/about" className="button">
              About Me
            </Link>
          ) : (
            <Link to="/resume" className="button">
              Resume
            </Link>
          )}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">
        &copy; Batchema Sombie <Link to="/">batchema.com</Link>.
      </p>
    </section>
  </section>
);

export default SideBar;
